export default class TaxRate {
    public id: string;
    public taxRateId: string;
    public description: string;
    public isExempt: boolean;
    public isRelayed: boolean;
    public percentage: number;

    constructor(obj?: Partial<TaxRate>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
