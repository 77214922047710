import { BaseService } from './baseService';
import { AxiosHelper } from '../helpers/axiosHelper';
import to from 'await-to-js';
import { SalesInvoice as Invoice, SalesInvoice } from '@/models/BrightInvoices/Invoice';
import { ApiResponse } from '@/models/BrightInvoices/Models';
import TaxRate from '@/models/CRM/TaxRate';
import Vue from 'vue';
import SendInvoiceMail from '@/models/SendInvoiceMail';

export class InvoiceService extends BaseService {
    private endpoint = Vue.$env().invoicesApiEndpoint;
    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getPurchaseInvoices(legalEntity: string, fromDate: string, untilDate: string): Promise<ApiResponse<Invoice>> {
        let url = `${this.endpoint}:legalEntity/purchase-invoices?`;

        if (fromDate) {
            url += `fromDate=${fromDate}`;
        }

        if (untilDate) {
            url += `&untilDate=${untilDate}`;
        }

        const [error, response] = await to(this.get<ApiResponse<Invoice>>(url, [['legalEntity', legalEntity]]));

        if (error) {
            console.error(error);
            return;
        }

        return response.data;
    }

    public async getSalesInvoices(legalEntity: string, fromDate: string, untilDate: string): Promise<Invoice[]> {
        let url = `${this.endpoint}:legalEntity/sales-invoices?`;

        if (fromDate) {
            url += `fromDate=${fromDate}`;
        }

        if (untilDate) {
            url += `&untilDate=${untilDate}`;
        }

        const [error, response] = await to(this.get<ApiResponse<Invoice>>(url, [['legalEntity', legalEntity]]));

        if (error) {
            console.error(error);
            return;
        }

        return response.data.items.map((x) => new SalesInvoice(x));
    }

    public async getInvoice(legalEntity: string, invoiceId: string, isSales: boolean) {
        const [err, response] = await to(
            this.get(`${this.endpoint}${legalEntity ? ':legalEntity/' : ''}${isSales ? 'sales' : 'purchase'}-invoices/:invoiceId`, [
                ['legalEntity', legalEntity],
                ['invoiceId', invoiceId],
            ]),
        );
        if (err) {
            return console.error('Failed to load invoice');
        }
        return response.data;
    }

    public async updateInvoice(invoice: SalesInvoice, legalEntity: string, isSales: boolean): Promise<any> {
        const model: InvoiceUpdate = {
            lines: invoice.lines.map((x) => {
                return {
                    ...x,
                    taxRateId: x.taxRate ? x.taxRate.taxRateId : null,
                    project: (x.project as any)?.name,
                    department: (x.department as any)?.name,
                };
            }),
            invoiceDescription: invoice.invoiceDescription,
            clientReference: invoice.clientReference,
            invoiceNumber: invoice.invoiceNumber,
            reference: invoice.reference,
            invoiceDate: invoice.invoiceDate,
            bookDate: invoice.bookDate,
            dueDate: invoice.dueDate,
            customer: invoice.customer,
            templateId: invoice.template?.invoiceTemplateId,
            currency: invoice.currency,
        };

        model.bookDate.setHours(12);

        const [err, response] = await to(
            this.put(`${this.endpoint}${legalEntity ? ':legalEntity/' : ''}${isSales ? 'sales' : 'purchase'}-invoices/:invoiceId`, model, [
                ['legalEntity', legalEntity],
                ['invoiceId', invoice.invoiceId],
            ]),
        );
        if (err) {
            return console.error('Failed to update invoice');
        }
        return response.data;
    }

    public async bookInvoice(invoice: any, legalEntity: string, isSales: boolean): Promise<any> {
        const [err, response] = await to(
            this.post(`${this.endpoint}${legalEntity ? ':legalEntity/' : ''}${isSales ? 'sales' : 'purchase'}-invoices/:invoiceId/book`, null, [
                ['legalEntity', legalEntity],
                ['invoiceId', invoice],
            ]),
        );
        if (err) {
            return console.error('Failed to book invoice');
        }
        return response.data;
    }

    public async createInvoice(invoice: Invoice, isSales: boolean, legalEntity?: string): Promise<any> {
        this.showPending('CREATE_INVOICE_PENDING');

        if (isSales) {
            const [err, responseSale] = await to(
                this.post(`${this.endpoint}${legalEntity ? ':legalEntity/' : ''}sales-invoices`, invoice, [['legalEntity', legalEntity]]),
            );
            if (err) {
                this.clearAndShowError('CREATE_INVOICE_FAILED', err);
                return console.error('Failed to create invoice');
            }

            this.clearAndShowSuccess('CREATE_INVOICE_SUCCESS');
            return responseSale.data;
        }

        const [error, response] = await to(
            this.post(`${this.endpoint}${legalEntity ? ':legalEntity/' : ''}purchase-invoices`, invoice, [['legalEntity', legalEntity]]),
        );
        if (error) {
            this.clearAndShowError('CREATE_INVOICE_FAILED', error);
            return console.error('Failed to create invoice');
        }
        this.clearAndShowSuccess('CREATE_INVOICE_SUCCESS');
        return response.data;
    }

    public async sendInvoice(invoiceId: string, legalEntity: string, sendMail: SendInvoiceMail, type: string = 'newInvoice'): Promise<any> {
        this.showPending('Sending mail..');

        const postModel = {
            contactName: sendMail.contactName,
            contactEmail: sendMail.contactEmail,
            ccEmail: sendMail.ccEmail,
            emailTemplateOverride: null,
        };

        if (sendMail.body && sendMail.subject && sendMail.masterTemplate) {
            postModel.emailTemplateOverride = {
                masterTemplate: sendMail.masterTemplate,
                body: sendMail.body,
                subject: sendMail.subject,
            };
        }

        let url = `${this.endpoint}${legalEntity}/sales-invoices/${invoiceId}/send`;
        if (type === 'reminder') {
            url = `${this.endpoint}${legalEntity}/sales-invoices/${invoiceId}/send-reminder`;
        }

        const [err] = await to(this.post(url, postModel));
        if (err) {
            this.clearAndShowError('FAILED_SENDING_INVOICE', err);
            return false;
        }
        return true;
    }

    public async receivePayment(invoiceId: string, legalEntity: string, fullyPaid: boolean, amount: number): Promise<any> {
        this.showPending('REGISTER_PAYMENT_PENDING');

        const [err] = await to(
            this.post(`${this.endpoint}${legalEntity}/sales-invoices/${invoiceId}/register-payment`, {
                amount,
                fullyPaid,
            }),
        );
        if (err) {
            this.clearAndShowError('FAILED_REGISTER_PAYMENT', err);
            return false;
        }
        return true;
    }

    public async getTaxRates(): Promise<TaxRate[]> {
        const [error, response] = await to(this.get(`${this.endpoint}tax-rates`));

        if (error) {
            console.error(error);
            return [];
        }

        return response.data.map((x) => new TaxRate(x));
    }

    public async getTransaction(secret: string, transactionId: string): Promise<any> {
        const [error, response] = await to(this.get(`${this.endpoint}transactions/${secret}/${transactionId}`));

        if (error) {
            console.error(error);
            return [];
        }

        return response.data;
    }

    public async postTransaction(secret: string, transactionId: string, transaction): Promise<any> {
        const [error, response] = await to(
            this.post(`${this.endpoint}transactions/${secret}/${transactionId}`, {
                contactName: transaction.contactName,
                contactEmail: transaction.contactEmail,
            }),
        );

        if (error) {
            console.error(error);
            return;
        }

        return response.data;
    }

    public async deleteInvoice(invoice: any, legalEntity: string, isSales: boolean): Promise<any> {
        if (isSales) {
            const [err] = await to(
                this.delete(`${this.endpoint}:legalEntity/sales-invoices/:invoiceId`, [
                    ['legalEntity', legalEntity],
                    ['invoiceId', invoice.invoiceId],
                ]),
            );
            if (err) {
                console.error('Failed to delete invoice');
                return false;
            }
            return true;
        }

        const [error] = await to(
            this.delete(`${this.endpoint}:legalEntity/purchase-invoices/:invoiceId`, [
                ['legalEntity', legalEntity],
                ['invoiceId', invoice.invoiceId],
            ]),
        );
        if (error) {
            console.error('Failed to delete invoice');
            return false;
        }
        return true;
    }

    public async creditInvoice(invoice: any, legalEntity: string, isSales: boolean, invoiceDate: Date): Promise<any> {
        if (isSales) {
            const [err, responseSale] = await to(
                this.post(`${this.endpoint}:legalEntity/sales-invoices/:invoiceId/credit`, { invoiceDate }, [
                    ['legalEntity', legalEntity],
                    ['invoiceId', invoice.invoiceId],
                ]),
            );
            if (err) {
                console.error('Failed to credit invoice');
                return false;
            }
            return responseSale.data;
        }

        const [error, response] = await to(
            this.post(`${this.endpoint}:legalEntity/purchase-invoices/:invoiceId/credit`, { invoice }, [
                ['legalEntity', legalEntity],
                ['invoiceId', invoice.invoiceId],
            ]),
        );
        if (error) {
            console.error('Failed to credit invoice');
            return false;
        }
        return response.data;
    }

    public async registerPayment(invoice: any, legalEntity: string, isSales: boolean, amount: number, fullyPaid: boolean): Promise<any> {
        if (isSales) {
            const [err, responseSale] = await to(
                this.post(`${this.endpoint}:legalEntity/sales-invoices/:invoiceId/register-payment`, { amount, fullyPaid }, [
                    ['legalEntity', legalEntity],
                    ['invoiceId', invoice.invoiceId],
                ]),
            );
            if (err) {
                return console.error('Failed to credit invoice');
            }
            return responseSale.data;
        }

        const [error, response] = await to(
            this.post(`${this.endpoint}:legalEntity/purchase-invoices/:invoiceId/register-payment`, { amount, fullyPaid }, [
                ['legalEntity', legalEntity],
                ['invoiceId', invoice.invoiceId],
            ]),
        );
        if (error) {
            return console.error('Failed to credit invoice');
        }
        return response.data;
    }

    public async pdfInvoice(invoiceId: string, legalEntity: string) {
        const [err, response] = await to(
            this.get(`${this.endpoint}${legalEntity}/sales-invoices/${invoiceId}/pdf`, null, {
                responseType: 'blob',
                transformResponse: null,
            }),
        );
        return !err ? response.data : null;
    }

    public async convertCurrency(sales: boolean, invoiceId: string, legalEntity: string) {
        const endpoint = sales ? 'sales-invoices' : 'purchase-invoices';
        await to(this.post(`${this.endpoint}${legalEntity}/${endpoint}/${invoiceId}/convert-currency`));
    }
}

export class InvoiceUpdate {
    public lines: any[] = [];
    public invoiceDescription: string;
    public clientReference: string;
    public invoiceNumber: string;
    public reference: string;
    public invoiceDate: Date;
    public bookDate: Date;
    public dueDate: Date;
    public customer: any;
    public templateId: number;
    public currency: string;
}
